import { FaCaretRight } from "react-icons/fa";

export const aboutMainItem = [
    { id: 1, title: "Company" },
    { id: 2, title: "Project Costing" },
    { id: 3, title: "Dedicated resources" },
    // { id: 4, title: "City we work in" },
];
0;
export const companyData1 = [
    {
        id: 1,
        image: "/assets/Header/About/Company/Our-company.svg",
        href: "/about/our-company",
        title: "Our Company",
    },
    {
        id: 2,
        image: "/assets/Header/About/Company/how-we-work.svg",
        href: "/about/how-we-work",
        title: "How We Work",
    },
    {
        id: 3,
        image: "/assets/Header/About/Company/csr.svg",
        href: "/about/corporate-social-responsibility",
        title: "CSR",
    },
    {
        id: 4,
        image: "/assets/Header/About/Company/awards.svg",
        href: "/awards",
        title: "Awards",
    },
    {
        id: 5,
        image: "/assets/Header/About/Company/core-team.svg",
        href: "/about/core-team",
        title: "Core Team",
    },
    // {
    //     id: 5,
    //     image: "/assets/Header/About/Company/agile-development.svg",
    //     href: "/agile-software-development-company",
    //     title: "Agile development",
    // },
    {
        id: 7,
        image: "/assets/Header/About/Company/career.svg",
        href: "/about/career",
        title: "Career",
    },
];

export const companyData2 = [
    {
        id: 1,
        image: "/assets/Header/About/Company/Client-Testimonials.svg",
        href: "/client-testimonials",
        title: "Client Testimonials",
    },
];

// export const companyData2 = {
//     image: "/assets/Header/Common/full-cycle-product-development.svg",
//     href: "/full-cycle-product-development-company",
//     title: "Full cycle product development",
//     service: [
//         {
//             id: 1,
//             image: <FaCaretRight />,
//             href: "/product-management",
//             title: "Product management",
//         },
//         {
//             id: 2,
//             image: <FaCaretRight />,
//             href: "/mvp-development-company",
//             title: "MVP",
//         },
//         {
//             id: 3,
//             image: <FaCaretRight />,
//             href: "/startup-app-development-company",
//             title: "Startups",
//         },
//         {
//             id: 4,
//             image: <FaCaretRight />,
//             href: "/enterprise-software-development-company",
//             title: "Enterprise",
//         },
//     ],
// };

export const projectCosting1 = [
    {
        id: 1,
        image: "/assets/Header/About/Project Costing/Proof-of-Concept.svg",
        href: "/pricing/proof-of-concept",
        title: "Proof of Concept (PoC)",
    },
    {
        id: 2,
        image: "/assets/Header/About/Project Costing/System-Audit.svg",
        href: "/pricing/system-audit",
        title: "System Audit",
    },
    {
        id: 3,
        image: "/assets/Header/About/Project Costing/Software-Maintenance.svg",
        href: "/pricing/software-maintenance",
        title: "Software Maintenance",
    },
    {
        id: 4,
        image: "/assets/Header/About/Project Costing/Product-Discovery.svg",
        href: "/pricing/product-discovery",
        title: "Product Discovery",
    },
    {
        id: 5,
        image: "/assets/Header/About/Project Costing/IT-Consulting.svg",
        href: "/pricing/it-consulting",
        title: "IT Consulting",
    },
    {
        id: 6,
        image: "/assets/Header/About/Project Costing/Dedicated Development Resources.svg",
        href: "/pricing/dedicated-development-resources",
        title: "Dedicated Development Resources",
    },
];

export const dedicatedResources1 = [
    {
        id: 1,
        image: "/assets/Header/About/Dedicated resources/Hire Blockchain Developers.svg",
        href: "/hire/blockchain-developers",
        title: "Hire Blockchain Developers",
    },
    {
        id: 2,
        image: "/assets/Header/About/Dedicated resources/Hire ReactJS Developers.svg",
        href: "/hire/react-js-developers",
        title: "Hire ReactJS Developers",
    },
    {
        id: 3,
        image: "/assets/Header/About/Dedicated resources/Hire React Native Developers.svg",
        href: "/hire/react-native-developers",
        title: "Hire React Native Developers",
    },
    {
        id: 4,
        image: "/assets/Header/About/Dedicated resources/Hire Next JS Developers.svg",
        href: "/hire/next-js-developers",
        title: "Hire Next JS Developers",
    },
    {
        id: 5,
        image: "/assets/Header/About/Dedicated resources/Hire Tailwind CSS Developers.svg",
        href: "/hire/tailwind-css-developers",
        title: "Hire Tailwind CSS Developers",
    },
    {
        id: 6,
        image: "/assets/Header/About/Dedicated resources/Hire Nuxt JS Developers.svg",
        href: "/hire/nuxt-js-developers",
        title: "Hire Nuxt JS Developers",
    },
    {
        id: 7,
        image: "/assets/Header/About/Dedicated resources/Hire Full Stack Blockchain Developers.svg",
        href: "/hire/bern-full-stack-developers",
        title: "Hire Bern Full Stack Developers",
    },
    {
        id: 8,
        image: "/assets/Header/About/Dedicated resources/Hire C language Developers.svg",
        href: "/hire/c-programming-developers",
        title: "Hire C language Developers",
    },
    {
        id: 9,
        image: "/assets/Header/About/Dedicated resources/Hire PHP Developers.svg",
        href: "/hire/php-developers ",
        title: "Hire PHP Developers",
    },
    {
        id: 10,
        image: "/assets/Header/About/Dedicated resources/Hire DeFi Developers.svg",
        href: "/hire/defi-developers ",
        title: "Hire DeFi Developers",
    },
    {
        id: 11,
        image: "/assets/Header/About/Dedicated resources/Hire Xamarin Dedicated Developer.svg",
        href: "/hire/xamarin-developers",
        title: "Hire Xamarin Developers",
    },
    {
        id: 12,
        image: "/assets/Header/About/Dedicated resources/Hire Vue Developer.svg",
        href: "/hire/vue-js-developers",
        title: "Hire Vue Js Developers",
    },
];

export const dedicatedResources2 = [
    {
        id: 1,
        image: "/assets/Header/About/Dedicated resources/Hire Angular JS Developers.svg",
        href: "/hire/angular-js-developers",
        title: "Hire Angular JS Developers",
    },
    {
        id: 2,
        image: "/assets/Header/About/Dedicated resources/Hire Node JS Developers.svg",
        href: "/hire/node-js-developers",
        title: "Hire Node JS Developers",
    },
    {
        id: 3,
        image: "/assets/Header/About/Dedicated resources/Hire Flutter Developers.svg",
        href: "/hire/flutter-developers",
        title: "Hire Flutter Developers",
    },
    {
        id: 4,
        image: "/assets/Header/About/Dedicated resources/Hire Rust Developers.svg",
        href: "/hire/rust-developers",
        title: "Hire Rust Developers",
    },
    {
        id: 5,
        image: "/assets/Header/About/Dedicated resources/Hire Golang Developers.svg",
        href: "/hire/golang-developers",
        title: "Hire Golang Developers",
    },
    {
        id: 6,
        image: "/assets/Header/About/Dedicated resources/Hire Solidity Developers.svg",
        href: "/hire/solidity-developers",
        title: "Hire Solidity Developers",
    },
    {
        id: 7,
        image: "/assets/Header/About/Dedicated resources/Hire Python Dev Developers.svg",
        href: "/hire/python-developers",
        title: "Hire Python Dev Developers",
    },
    {
        id: 8,
        image: "/assets/Header/About/Dedicated resources/Hire C_plus_plus Developers.svg",
        href: "/hire/c-plus-plus-developers",
        title: "Hire C++ Developers",
    },
    {
        id: 9,
        image: "/assets/Header/About/Dedicated resources/Hire Java Developers.svg",
        href: "/hire/java-developers",
        title: "Hire Java Developers",
    },
    {
        id: 10,
        image: "/assets/Header/About/Dedicated resources/Hire Liveops Developer.svg",
        href: "/hire/liveops-developers",
        title: "Hire Liveops Developer",
    },
    {
        id: 11,
        image: "/assets/Header/About/Dedicated resources/Hire Typescript Developer.svg",
        href: "/hire/typescript-developers",
        title: "Hire Typescript Developer",
    },
    {
        id: 12,
        image: "/assets/Header/About/Dedicated resources/Hire CakePHP Developer.svg",
        href: "/hire/cakephp-developers",
        title: "Hire CakePHP Developer",
    },
];

export const dedicatedResources3 = [
    {
        id: 1,
        image: "/assets/Header/About/Dedicated resources/Hire Abstraction Developers.svg",
        href: "/hire/abstraction-developers",
        title: "Hire Abstraction Developers",
    },
    {
        id: 2,
        image: "/assets/Header/About/Dedicated resources/Hire DevOps Developers.svg",
        href: "/hire/devops-developers",
        title: "Hire DevOps Developers",
    },
    {
        id: 3,
        image: "/assets/Header/About/Dedicated resources/Hire Etheream Developers.svg",
        href: "/hire/ethereum-developers",
        title: "Hire Ethereum Developers",
    },
    {
        id: 4,
        image: "/assets/Header/About/Dedicated resources/Hire Solana Developers.svg",
        href: "/hire/solana-developers",
        title: "Hire Solana Developers",
    },
    {
        id: 5,
        image: "/assets/Header/About/Dedicated resources/Hire Javascript Developers.svg",
        href: "/hire/javascript-developers",
        title: "Hire Javascript Developers",
    },
    {
        id: 6,
        image: "/assets/Header/About/Dedicated resources/Hire MERN Stack Developers.svg",
        href: "/hire/mern-stack-developers",
        title: "Hire MERN Stack Developers",
    },
    {
        id: 7,
        image: "/assets/Header/About/Dedicated resources/Hire Nest JS Developers.svg",
        href: "/hire/nest-js-developers",
        title: "Hire Nest JS Developers",
    },
    {
        id: 8,
        image: "/assets/Header/About/Dedicated resources/Hire Laravel Developers.svg",
        href: "/hire/laravel-developers",
        title: "Hire Laravel Developers",
    },
    {
        id: 9,
        image: "/assets/Header/About/Dedicated resources/Hire UI UX Designers.svg",
        href: "/hire/ui-ux-designers",
        title: "Hire UI UX Designers",
    },
    {
        id: 10,
        image: "/assets/Header/About/Dedicated resources/Hire Ionic Dedicated Developer.svg",
        href: "/hire/ionic-developers",
        title: "Hire Ionic Dedicated Developers",
    },
    {
        id: 11,
        image: "/assets/Header/About/Dedicated resources/Hire Codeigniter Developer.svg",
        href: "/hire/codeigniter-developers",
        title: "Hire Codeigniter Developer",
    },
];

export const cityWeWorkIn1 = [
    {
        id: 1,
        image: "/assets/Header/About/City work/New York.svg",
        href: "/",
        title: "New York",
    },
    {
        id: 2,
        image: "/assets/Header/About/City work/Paris.svg",
        href: "/",
        title: "Paris",
    },
    {
        id: 3,
        image: "/assets/Header/About/City work/Beijing.svg",
        href: "/",
        title: "Beijing",
    },
    {
        id: 4,
        image: "/assets/Header/About/City work/Mexico City.svg",
        href: "/",
        title: "Mexico City",
    },
    {
        id: 5,
        image: "/assets/Header/About/City work/São Paulo.svg",
        href: "/",
        title: "São Paulo",
    },
    {
        id: 6,
        image: "/assets/Header/About/City work/Hong Kong.svg",
        href: "/",
        title: "Hong Kong",
    },
    {
        id: 7,
        image: "/assets/Header/About/City work/Vienna.svg",
        href: "/",
        title: "Vienna",
    },
    {
        id: 8,
        image: "/assets/Header/About/City work/Dubai.svg",
        href: "/",
        title: "Dubai",
    },
    {
        id: 9,
        image: "/assets/Header/About/City work/Prague.svg",
        href: "/",
        title: "Prague",
    },
];

export const cityWeWorkIn2 = [
    {
        id: 1,
        image: "/assets/Header/About/City work/Toronto.svg",
        href: "/",
        title: "Toronto",
    },
    {
        id: 2,
        image: "/assets/Header/About/City work/Osaka.svg",
        href: "/",
        title: "Osaka",
    },
    {
        id: 3,
        image: "/assets/Header/About/City work/London.svg",
        href: "/",
        title: "London",
    },
    {
        id: 4,
        image: "/assets/Header/About/City work/Guangzhou.svg",
        href: "/",
        title: "Guangzhou",
    },
    {
        id: 5,
        image: "/assets/Header/About/City work/Lisbon.svg",
        href: "/",
        title: "Lisbon",
    },
    {
        id: 6,
        image: "/assets/Header/About/City work/Hangzhou.svg",
        href: "/",
        title: "Hangzhou",
    },
    {
        id: 7,
        image: "/assets/Header/About/City work/Tokyo.svg",
        href: "/",
        title: "Tokyo",
    },
    {
        id: 8,
        image: "/assets/Header/About/City work/Amsterdam.svg",
        href: "/",
        title: "Amsterdam",
    },
    {
        id: 9,
        image: "/assets/Header/About/City work/Sydney.svg",
        href: "/",
        title: "Sydney",
    },
];

export const cityWeWorkIn3 = [
    {
        id: 1,
        image: "/assets/Header/About/City work/Dhaka.svg",
        href: "/",
        title: "Dhaka",
    },
    {
        id: 2,
        image: "/assets/Header/About/City work/Barcelona.svg",
        href: "/",
        title: "Barcelona",
    },
    {
        id: 3,
        image: "/assets/Header/About/City work/Istanbul.svg",
        href: "/",
        title: "Istanbul",
    },
    {
        id: 4,
        image: "/assets/Header/About/City work/Madrid.svg",
        href: "/",
        title: "Madrid",
    },
    {
        id: 5,
        image: "/assets/Header/About/City work/Cape Town.svg",
        href: "/",
        title: "Cape Town",
    },
    {
        id: 6,
        image: "/assets/Header/About/City work/Seoul.svg",
        href: "/",
        title: "Seoul",
    },
    {
        id: 7,
        image: "/assets/Header/About/City work/Chicago.svg",
        href: "/",
        title: "Chicago",
    },
    {
        id: 8,
        image: "/assets/Header/About/City work/Los Angeles.svg",
        href: "/",
        title: "Los Angeles",
    },
    {
        id: 9,
        image: "/assets/Header/About/City work/Rio de Janeiro.svg",
        href: "/",
        title: "Rio de Janeiro",
    },
];
