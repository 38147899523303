import React from "react";

const ServiceUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>Discover</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/discover/scoping-sessions">
                                        Scoping Sessions
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/discover/business-analysis">
                                        Business Analysis
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/discover/product-discovery-workshop-and-design-sprints">
                                        Product Discovery Workshop & Design
                                        Sprints
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Design</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/design/user-experience">
                                        User Experience Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/design/user-interface">
                                        User Interface Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/design/ux-audit">
                                        User Experience Review
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>services</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/services/cloud">
                                        Cloud Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/website-development">
                                        Website Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/react-native-development">
                                        React Native
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/progressive-web-app">
                                        PWA
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/wearable-app-development">
                                        Wearable
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/ar-vr">
                                        AR-VR
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/internet-of-things">
                                        Internet of Things
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/devops">
                                        DevOps
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/ai-ml">
                                        AI-ML Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/software-development">
                                        Software Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/data-science-analytics">
                                        Data Science Analytics
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/business-intelligence">
                                        Business Intelligence
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/mobile-app-development">
                                        Mobile App Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/end-to-end-product-development">
                                        End-to-End Product Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/services/flutter">
                                        Flutter
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Scale</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                
                                <li>
                                    <a href="https://www.tanthetaa.com/scale/digital-transformation">
                                        Digital Transformation
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/scale/product-launch-and-growth-hacking">
                                        Product Launch and Growth Hacking
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default ServiceUrl;
