import React from "react";

const AboutUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>Company</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/about/our-company">
                                        Our Company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/about/how-we-work">
                                        How We Work
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/about/corporate-social-responsibility">
                                        CSR
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/awards">
                                        Awards
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/about/core-team">
                                        Core Team
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/agile-software-development-company">
                                        Agile Software Development Company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/about/career">
                                        Career
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/client-testimonials">
                                        Client Testimonials
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/full-cycle-product-development-company">
                                        Full-Cycle Product Development Company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/product-management">
                                        Product Management
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/mvp-development-company">
                                        mMVP Development Company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/startup-app-development-company">
                                        Startup App Development Company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/enterprise-software-development-company">
                                        Enterprise Software Development Company
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Project Costing</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu">
                                <li>
                                    <a href="https://www.tanthetaa.com/pricing/proof-of-concept">
                                        Proof ofConcept
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/pricing/system-audit">
                                        System Audit
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/pricing/software-maintenance">
                                        Software Maintenance
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/pricing/product-discovery">
                                        Product Discovery
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/pricing/it-consulting">
                                        IT Consulting
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/pricing/dedicated-development-resources">
                                        Dedicated Development Resources
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Dedicated Resources</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu">
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/blockchain-developers">
                                        Hire Blockchain Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/react-js-developers">
                                        Hire ReactJS Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/react-native-developers">
                                        Hire React Native Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/next-js-developers">
                                        Hire Next JS Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/tailwind-css-developers">
                                        Hire Tailwind CSS Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/nuxt-js-developers">
                                        Hire Nuxt JS Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/angular-js-developers">
                                        Hire Angular JS Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/node-js-developers">
                                        Hire Node JS Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/flutter-developers">
                                        Hire Flutter Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/rust-developers">
                                        Hire Rust Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/golang-developers">
                                        Hire Golang Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/solidity-developers">
                                        Hire Solidity Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/abstraction-developers">
                                        Hire Abstraction Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/devops-developers">
                                        Hire DevOps Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/ethereum-developers">
                                        Hire Ethereum Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/solana-developers">
                                        Hire Solana Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/javascript-developers">
                                        Hire Javascript Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/mern-stack-developers">
                                        Hire MERN Stack Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/bern-full-stack-developers">
                                        Hire BERN Full Stack Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/python-developers">
                                        Hire Python Dev Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/nest-js-developers">
                                        Hire Nest JS Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/c-programming-developers">
                                        Hire C Language Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/c-plus-plus-developers">
                                        Hire C++ Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/laravel-developers">
                                        Hire Laravel Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/php-developers">
                                        Hire PHP Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/java-developers">
                                        Hire Java Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/defi-developers">
                                        Hire DeFi Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/liveops-developers">
                                        Hire LiveOps Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/ui-ux-designers">
                                        Hire UI UX Designers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/ionic-developers">
                                        Hire Ionic Dedicated Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/xamarin-developers">
                                        Hire Xamarin Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/typescript-developers">
                                        Hire Typescript Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/codeigniter-developers">
                                        Hire Codeigniter Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/cakephp-developers">
                                        Hire PHP Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/hire/vue-js-developers">
                                        Hire VueJS Developers
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default AboutUrl;
