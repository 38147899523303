import React from "react";

const IndustriesUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>On-Demand</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/on-demand/consumer-goods-and-services">
                                        Consumer goods
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/on-demand/crowdfunding-consulting-services">
                                        Crowdfunding
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/on-demand/education-app-development">
                                        Education
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/on-demand/recruiting-app-development">
                                        Recruiting
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/on-demand/delivery-app-development">
                                        Delivery
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/on-demand/food-delivery-app-development">
                                        Food
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/on-demand/logistics-and-transportation-services">
                                        Transportation
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Food & Restaurant</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/point-of-sale-systems-services">
                                        Point-of-sale systems
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/accounting-softwares-service">
                                        Accounting software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/loyalty-programs-development">
                                        Loyalty programs
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/online-ordering-platform-development">
                                        Online ordering platforms
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/third-party-delivery-apps">
                                        Third-party delivery apps
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/training-development-services">
                                        Employee training applications
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/software-development-planning">
                                        Scheduling software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/food-and-restaurant/payroll-software-development">
                                        Payroll programs
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Banking</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/finance-app-development">
                                        Investments
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/core-banking-software-development">
                                        Core Banking
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/corporate-banking-development-services">
                                        Corporate Banking
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/loan-lending-app-development">
                                        Credits and Loans
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/fraud-detection-software-development-company">
                                        Fraud Protection
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/ai-development-services">
                                 AI Development Services for Banking
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/risk-management-software-development">
                                        Risk Management
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/banking/wealth-management-software-development">
                                        Wealth Management
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Ecommerce</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/online-store-app-development">
                                        online Stores
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/website-development">
                                    Ecommerce Web development Company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/website-design">
                                        Ecommerce Website Design services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/marketplace-development">
                                        E-commerce Marketplace Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/b2c-marketplace-development">
                                        B2B Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/b2b-portals-development">
                                        B2B Portals
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/custom-auction-software-development">
                                        Auctions and Bidding Platforms
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/ecommerce-aggregators-app">
                                        Ecommerce Aggregators
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/ticket-booking-solutions">
                                        Booking and Ticketing Solutions
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/design-concept-development">
                                        Design concept
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/software-development-services">
                                    Ecommerce Software Development Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/shopping-cart-development">
                                        Shopping Cart Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/store-customization-services">
                                        E-commerce Store Customization
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/mobile-app-development">
                                        E-commerce Mobile Application
                                        Development Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/payment-gateway-integration">
                                        E-commerce Payment Gateway Integration
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/chatbot-development-company">
                                        E-commerce Chatbot integration
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/analytics-services">
                                        E-commerce Analytics
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ecommerce/retail-ecommerce-software-development">
                                        E-commerce and Retail Software
                                        Development Solutions
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Healthcare</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/electronic-health-records-software">
                                        Electronic Health Records Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/dynamics-365-health-crm-solution">
                                        Healthcare CRM Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/telemedicine-Software-development-services">
                                        Telemedicine Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/healthcare-billing-software-development">
                                        Healthcare billing software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/clinical-trial-management-software">
                                        Clinical trial management software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/hospital-management-software-system">
                                        Hospital Management Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/phr-app-development">
                                        PHR App Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/ctms-solutions">
                                        Clinical trial management systems
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/remote-patient-monitoring">
                                        Remote Patient Monitoring Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/e-prescription-software-development">
                                        E-prescribing software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/healthcare-mobile-app-development">
                                        Healthcare Mobile app Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/medical-research">
                                        Medical research software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/fitness-tracking-app-development">
                                        Fitness Tracking app development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/healthcare/medical-image-analysis-software">
                                        Medical Image Analysis Software
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Education</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/education/authoring-software-development-systems">
                                        Authoring Systems
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/math-problem-solving-software-development">
                                        Assessment Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/assessment-software-development">
                                        Drill & Practics Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/courseware-development">
                                        Special Needs Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/drill-and-practice-software-development">
                                        Courseware Development Service
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/educational-robotics-mbl">
                                        Programmable Systems
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/virtual-reality-development-services">
                                        Virtual Reality Applications
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/programmable-system">
                                        Hypermedia
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/special-needs-software">
                                        Tutorial Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/adaptive-hypermedia-system">
                                        Reference Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/utility-software-development">
                                        Math Problem Solving Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/graphic-software-development">
                                        Educational Robotics & MBL
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/intelligent-tutoring-system">
                                        Graphic Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/tutorial-software-development">
                                        Utility Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/collaborative-distance-learning-system">
                                        Intelligent Tutoring Systems
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/education/reference-software-development">
                                        Collaborative Distance Learning Systems
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default IndustriesUrl;
