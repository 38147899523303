export const servicesMainItemMobile = [
    {
        id: 1,
        title: "Discover",
        discover: [
            {
                id: 1,
                title: "Scoping Sessions",
                description:
                    "Enlighten our tech experts about your breakthrough idea in an intensive session. We characterise your product idea and define the Scope of work.",
                href: "/discover/scoping-sessions",
                image: "/assets/Header/Services/Discover/Scoping_sessions.svg",
            },
            {
                id: 2,
                title: "Business Analysis",
                description:
                    "We elicit business needs, study the competitive landscape, perform strategic analysis, and provide bespoke solutions.",
                href: "/discover/business-analysis",
                image: "/assets/Header/Services/Discover/Business analysis.svg",
            },
            {
                id: 3,
                title: "Product Discovery",
                description:
                    "Define your product strategy, prioritize features and visualize the end results with our strategic Discovery workshops. Validate assumptions with real users and find answers to most pressing concerns with Design Sprint.",
                href: "/discover/product-discovery-workshop-and-design-sprints",
                image: "/assets/Header/Services/Discover/Product discovery workshop & design sprints.svg",
            },
            // {
            //   id: 4,
            //   title: "Full cycle product development",
            //   description:
            //     "We validate early and iterate often. From ideation to launch, we follow a holistic approach to full-cycle product development.",
            //   link: "Know more --->",
            //   href: "/",
            //   image: "/assets/Header/About/Our-company.svg",
            // },
            // {
            //   id: 5,
            //   title: "Innovation Lab",
            //   description:
            //     "We are early adopters of disruptive technologies. Unravel unique insights on our technological know-how and thought leadership.",
            //   link: "Know more --->",
            //   href: "/",
            //   image: "/assets/Header/About/Our-company.svg",
            // },
        ],
    },
    {
        id: 2,
        title: "Design",
        design: [
            {
                id: 1,
                title: "User Experience Design",
                description:
                    "Seamlessly integrate branding, functionality, usability and accessibility into your product. We enhance user interaction and deliver experiences that are meaningful and delightful.",
                href: "/design/user-experience",
                image: "/assets/Header/Services/Design/User Experience Design.svg",
            },
            {
                id: 2,
                title: "User Interface Design",
                description:
                    "We enhance usability and craft designs that are unconventional and intuitively guides users into a splendid visual journey.",
                href: "/design/user-interface",
                image: "/assets/Header/Services/Design/User Interface Design.svg",
            },
            {
                id: 3,
                title: "User Experience Review",
                description:
                    "Identify usability issues, discuss UX improvements, and radically improve your digital product with our UX review sessions.",
                href: "/design/ux-audit",
                image: "/assets/Header/Services/Design/User Experience Review.svg",
            },
            // {
            //   id: 4,
            //   title: "Full cycle product development",
            //   description:
            //     "We validate early and iterate often. From ideation to launch, we follow a holistic approach to full-cycle product development.",
            //   link: "Know more --->",
            //   href: "/",
            //   image: "/assets/Header/About/Our-company.svg",
            // },
            // {
            //   id: 5,
            //   title: "Innovation Lab",
            //   description:
            //     "We are early adopters of disruptive technologies. Unravel unique insights on our technological know-how and thought leadership.",
            //   link: "Know more --->",
            //   href: "/",
            //   image: "/assets/Header/About/Our-company.svg",
            // },
        ],
    },
    {
        id: 3,
        title: "Services",
        engineer: [
            {
                id: 1,
                image: "/assets/Header/Services/Engineer/Flutter.svg",
                title: "Flutter",
                href: "/services/flutter",
            },
            {
                id: 2,
                image: "/assets/Header/Services/Engineer/Cloud Services.svg",
                title: "Cloud Services",
                href: "/services/cloud",
            },
            {
                id: 3,
                image: "/assets/Header/Services/Engineer/Web development.svg",
                title: "Website Development",
                href: "/services/website-development",
            },
            {
                id: 4,
                image: "/assets/Header/Services/Engineer/React native.svg",
                title: "React Native Development",
                href: "/services/react-native-development",
            },
            {
                id: 5,
                image: "/assets/Header/Services/Engineer/PWA.svg",
                title: "PWA Web-App",
                href: "/services/progressive-web-app",
            },
            {
                id: 6,
                image: "/assets/Header/Services/Engineer/Wearable.svg",
                title: "Wearable App Development",
                href: "/services/wearable-app-development",
            },
            {
                id: 7,
                image: "/assets/Header/Services/Engineer/AR VR.svg",
                title: "AR/VR",
                href: "/services/ar-vr",
            },
            {
                id: 8,
                image: "/assets/Header/Services/Engineer/Internet of Things.svg",
                title: "Internet of Things",
                href: "/services/internet-of-things",
            },
            {
                id: 9,
                image: "/assets/Header/Services/Engineer/Dev Ops.svg",
                title: "DevOps",
                href: "/services/devops",
            },
            {
                id: 10,
                image: "/assets/Header/Services/Engineer/AI-ML-development.svg",
                title: "AI-ML",
                href: "/services/ai-ml",
            },
            {
                id: 11,
                image: "/assets/Header/Services/Engineer/Software development.svg",
                title: "Software Development",
                href: "/services/software-development",
            },
            {
                id: 12,
                image: "/assets/Header/Services/Engineer/Data science analytics.svg",
                title: "Data Science Analytics",
                href: "/services/data-science-analytics",
            },
            {
                id: 13,
                image: "/assets/Header/Services/Engineer/Business intelligence.svg",
                title: "Business Intelligence",
                href: "/services/business-intelligence",
            },
            {
                id: 14,
                image: "/assets/Header/Services/Engineer/end-to-end.svg",
                title: "End-to-End Product Development",
                href: "/services/end-to-end-product-development",
            },
            {
                id: 15,
                image: "/assets/Header/Services/Engineer/mobile-app.svg",
                title: "Mobile-App Development",
                href: "/services/mobile-app-development",
            },
            {
                id: 16,
                image: "/assets/Header/Services/Engineer/Web development.svg",
                title: "Website Development",
                href: "/services/website-development",
            },
        ],
    },
    {
        id: 4,
        title: "Scale",
        scale: [
          /*
{
    id: 1,
    title: "IT Consulting Services",
    description: "Our IT consulting services provide you the gears necessary for overcoming technological challenges.",
    href: "/scale/it-consulting",
    image: "/assets/Header/Services/Scale/IT Consulting Services.svg",
}
*/

            {
                id: 2,
                title: "Digital Transformation",
                description:
                    "We help you digitally transform and scale your business through the power of technology and innovation.",
                href: "/scale/digital-transformation",
                image: "/assets/Header/Services/Scale/Digital Transformation.svg",
            },
            {
                id: 3,
                title: "Product Launch and Growth Hacking",
                description:
                    "We outline low-budget innovative strategies, identify channels for rapid client acquisition and scale businesses to new heights.",
                href: "/scale/product-launch-and-growth-hacking",
                image: "/assets/Header/Services/Scale/Product Launch and Growth Hacking.svg",
            },
            // {
            //   id: 4,
            //   title: "Full cycle product development",
            //   description:
            //     "We validate early and iterate often. From ideation to launch, we follow a holistic approach to full-cycle product development.",
            //   link: "Know more --->",
            //   href: "/",
            //   image: "/assets/Header/About/Our-company.svg",
            // },
            // {
            //   id: 5,
            //   title: "Innovation Lab",
            //   description:
            //     "We are early adopters of disruptive technologies. Unravel unique insights on our technological know-how and thought leadership.",
            //   link: "Know more --->",
            //   href: "/",
            //   image: "/assets/Header/About/Our-company.svg",
            // },
        ],
    },
];
