// export const industriesMainItem = [
// { id: 1, title: "Education" },
// { id: 2, title: "Banking & Finance" },
// { id: 3, title: "Ecommerce" },
// { id: 4, title: "Gaming" },
// { id: 5, title: "Food & Restaurant" },
// { id: 6, title: "Taxi Booking" },
// { id: 7, title: "Dating" },
// { id: 8, title: "Travel & Transport" },
// { id: 9, title: "Events & Tickets" },
// { id: 10, title: "Social Networking" },
// { id: 11, title: "On Demand" },
// { id: 12, title: "Healthcare & Fitness" },
// ];
export const industriesMainItem = [
    { id: 1, title: "On-Demand" },
    { id: 2, title: "Food & Restaurant" }, //
    { id: 3, title: "Banking" }, //
    { id: 4, title: "Ecommerce" }, //
    { id: 5, title: "Healthcare" },
    { id: 6, title: "Education" }, //
];

export const education1 = [
    {
        id: 1,
        image: "/assets/Header/industries/education/authoring.svg",
        title: "Authoring Systems",
        href: "/education/authoring-software-development-systems",
    },
    {
        id: 2,
        image: "/assets/Header/industries/education/assessment.svg",
        title: "Assessment Software",
        href: "/education/assessment-software-development",
    },
    {
        id: 3,
        image: "/assets/Header/industries/education/drill.svg",
        title: "Drill & Practics Software",
        href: "/education/drill-and-practice-software-development",
    },
    {
        id: 4,
        image: "/assets/Header/industries/education/special.svg",
        title: "Special Needs Software",
        href: "/education/special-needs-software",
    },
    {
        id: 5,
        image: "/assets/Header/industries/education/courseware.svg",
        title: "Courseware Developmeny Services",
        href: "/education/courseware-development",
    },
    {
        id: 6,
        image: "/assets/Header/industries/education/programmable.svg",
        title: "Programmable Systems",
        href: "/education/programmable-system",
    },
    {
        id: 7,
        image: "/assets/Header/industries/education/vr.svg",
        title: "Virtual Reality Applications",
        href: "/education/virtual-reality-development-services",
    },
    {
        id: 8,
        image: "/assets/Header/industries/education/hyper.svg",
        title: "Hypermedia",
        href: "/education/adaptive-hypermedia-system",
    },
    {
        id: 9,
        image: "/assets/Header/industries/education/intelli.svg",
        title: "Intelligent Tutoring Systems",
        href: "/education/intelligent-tutoring-system",
    },
    {
        id: 10,
        image: "/assets/Header/industries/education/colla.svg",
        title: "Collaborative Distance Learning Systems",
        href: "/education/collaborative-distance-learning-system",
    },
];

export const education2 = [
    {
        id: 1,
        image: "/assets/Header/industries/education/tutorial.svg",
        title: "Tutorial Software",
        href: "/education/tutorial-software-development",
    },
    {
        id: 2,
        image: "/assets/Header/industries/education/reference.svg",
        title: "Reference Software",
        href: "/education/reference-software-development",
    },

    {
        id: 3,
        image: "/assets/Header/industries/education/math.svg",
        title: "Math Problem Solving Software",
        href: "/education/math-problem-solving-software-development",
    },

    {
        id: 4,
        image: "/assets/Header/industries/education/edrobotics.svg",
        title: "Educational Robotics & MBL",
        href: "/education/educational-robotics-mbl",
    },
    {
        id: 5,
        image: "/assets/Header/industries/education/graphic.svg",
        title: "Graphic Software",
        href: "/education/graphic-software-development",
    },
    {
        id: 6,
        image: "/assets/Header/industries/education/utility.svg",
        title: "Utility Software",
        href: "/education/utility-software-development",
    },
];

export const bankingFinance1 = [
    {
        id: 1,
        image: "/assets/Header/industries/banking/core.svg",
        title: "Core Banking",
        href: "/banking/core-banking-software-development",
    },
    {
        id: 2,
        image: "/assets/Header/industries/banking/corporate.svg",
        title: "Corporate Banking",
        href: "/banking/corporate-banking-development-services",
    },
    {
        id: 3,
        image: "/assets/Header/industries/banking/credit.svg",
        title: "Credits and Loans",
        href: "/banking/loan-lending-app-development",
    },
    {
        id: 4,
        image: "/assets/Header/industries/banking/fraud.svg",
        title: "Fraud Protection",
        href: "/banking/fraud-detection-software-development-company",
    },
    {
        id: 5,
        image: "/assets/Header/industries/banking/investment.svg",
        title: "Investments",
        href: "/banking/finance-app-development",
    },
    {
        id: 6,
        image: "/assets/Header/industries/banking/operatio.svg",
        title: "AI Development Services for Banking",
        href: "/banking/ai-development-services",
    },

    {
        id: 7,
        image: "/assets/Header/industries/banking/risk.svg",
        title: "Risk Management",
        href: "/banking/risk-management-software-development",
    },
    {
        id: 8,
        image: "/assets/Header/industries/banking/welth.svg",
        title: "Wealth Management",
        href: "/banking/wealth-management-software-development",
    },
];

export const ecommerce1 = [
    {
        id: 1,
        image: "/assets/Header/industries/ecommerce/online.svg",
        title: "Online Stores",
        href: "/ecommerce/online-store-app-development",
    },
    {
        id: 2,
        image: "/assets/Header/industries/ecommerce/b2bmarket.svg",
        title: "B2B Marketplace",
        href: "/ecommerce/b2c-marketplace-development",
    },
    {
        id: 3,
        image: "/assets/Header/industries/ecommerce/b2bportal.svg",
        title: "B2B Portals",
        href: "/ecommerce/b2b-portals-development",
    },
    {
        id: 4,
        image: "/assets/Header/industries/ecommerce/auctions.svg",
        title: "Auctions & Bidding Platforms",
        href: "/ecommerce/custom-auction-software-development",
    },
    {
        id: 5,
        image: "/assets/Header/industries/ecommerce/ecomm.svg",
        title: "Ecommerce Aggregators",
        href: "/ecommerce/ecommerce-aggregators-app",
    },
    {
        id: 6,
        image: "/assets/Header/industries/ecommerce/booking.svg",
        title: "Booking & Ticketing Solutions",
        href: "/ecommerce/ticket-booking-solutions",
    },
    {
        id: 7,
        image: "/assets/Header/industries/ecommerce/design.svg",
        title: "Design Concept",
        href: "/ecommerce/design-concept-development",
    },
    {
        id: 8,
        image: "/assets/Header/industries/ecommerce/web.svg",
        title: "Ecommerce Web Development Company",
        href: "/ecommerce/website-development",
    },
    {
        id: 9,
        image: "/assets/Header/industries/ecommerce/sof.svg",
        title: "Ecommerce Software Development Services",
        href: "/ecommerce/software-development-services",
    },
    {
        id: 10,
        image: "/assets/Header/industries/ecommerce/shopping.svg",
        title: "Shopping Cart Development",
        href: "/ecommerce/shopping-cart-development",
    },
];

export const ecommerce2 = [
    {
        id: 1,
        image: "/assets/Header/industries/ecommerce/ecomweb.svg",
        title: "Ecommerce Website Design And Development",
        href: "/ecommerce/website-design",
    },
    {
        id: 2,
        image: "/assets/Header/industries/ecommerce/store.svg",
        title: "E-commerce Store Customization",
        href: "/ecommerce/store-customization-services",
    },
    {
        id: 3,
        image: "/assets/Header/industries/ecommerce/marketplace.svg",
        title: "E-commerce Marketplace Development",
        href: "/ecommerce/marketplace-development",
    },
    {
        id: 4,
        image: "/assets/Header/industries/ecommerce/mobile.svg",
        title: "E-commerce Mobile Application Development Services",
        href: "/ecommerce/mobile-app-development",
    },
    {
        id: 5,
        image: "/assets/Header/industries/ecommerce/payment.svg",
        title: "E-commerce Payment Gateway Integration",
        href: "/ecommerce/payment-gateway-integration",
    },
    {
        id: 6,
        image: "/assets/Header/industries/ecommerce/chatbot.svg",
        title: "E-commerce Chatbot Development",
        href: "/ecommerce/chatbot-development-company",
    },
    {
        id: 7,
        image: "/assets/Header/industries/ecommerce/retail.svg",
        title: "E-commerce and Retail Software Development Solutions",
        href: "/ecommerce/retail-ecommerce-software-development",
    },
    {
        id: 8,
        image: "/assets/Header/industries/ecommerce/analytics.svg",
        title: "E-commerce Analytics",
        href: "/ecommerce/analytics-services",
    },
];

export const gaming1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Metal",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Amazon Lumberyard",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Blender",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Construct 3",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "GameMaker",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "CRYENGINE",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Godot Engine",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "Maya LT",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "Gamefroot",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "GDevelop",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "GameSalad",
        href: "/",
    },
];

export const gaming2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "DeltaEngine",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Panda3D",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Superpowers",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "RPG Maker VX Ace",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Cocos2d-x",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Corona",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Clickteam Fusion",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "MonoGame",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "NeoAxis Engine",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Photon",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "Helix Core",
        href: "/",
    },
];

export const gaming3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Leadwerks",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "SpriteKit",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Arcweave",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Stencyl",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Defold",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Starling",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Haxe",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "GamePlay3D",
        href: "/",
    },
];

export const foodRestaurant1 = [
    {
        id: 1,
        image: "/assets/Header/industries/food&resto/1.svg",
        title: "Point-of-Sale Systems",
        href: "/food-and-restaurant/point-of-sale-systems-services",
    },
    {
        id: 2,
        image: "/assets/Header/industries/food&resto/2.svg",
        title: "Accounting Software",
        href: "/food-and-restaurant/accounting-softwares-service",
    },
    {
        id: 3,
        image: "/assets/Header/industries/food&resto/3.svg",
        title: "Loyalty Programs",
        href: "/food-and-restaurant/loyalty-programs-development",
    },
    {
        id: 4,
        image: "/assets/Header/industries/food&resto/4.svg",
        title: "Online Ordering Platforms",
        href: "/food-and-restaurant/online-ordering-platform-development",
    },
    {
        id: 5,
        image: "/assets/Header/industries/food&resto/5.svg",
        title: "Third-Party Delivery Apps",
        href: "/food-and-restaurant/third-party-delivery-apps",
    },
    {
        id: 6,
        image: "/assets/Header/industries/food&resto/6.svg",
        title: "Employee Training Applications",
        href: "/food-and-restaurant/training-development-services",
    },
    {
        id: 7,
        image: "/assets/Header/industries/food&resto/7.svg",
        title: "Scheduling Software",
        href: "/food-and-restaurant/software-development-planning",
    },
    {
        id: 8,
        image: "/assets/Header/industries/food&resto/8.svg",
        title: "Payroll Programs",
        href: "/food-and-restaurant/payroll-software-development",
    },
];

export const texiBooking1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Onde",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Cabsoluit",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "AllRide",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "SpotnRides",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "SmartCar",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Infinite Cab",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Taxi Mobile Solutions",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "Limo n' Taxi",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "CabbyGo",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "TaxiCaller",
        href: "/",
    },
];

export const texiBooking2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "RideBits",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Mtoag Taxi App",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "ESuper",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "TaxiTapp",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Taxi On The Go",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Sherlock Taxi Solution",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "FareBookings.com",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "YelowTaxi",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "PerGo Dispatch",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Call Taxi Management System",
        href: "/",
    },
];

export const texiBooking3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Eber",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "TaxiMobility",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Taxi Pulse",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "UnicoTaxi",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Tagmytaxi",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "CabStartup",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Voila Cabs",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "BooknRide",
        href: "/",
    },
];

export const dating1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Bumble: Dating & Friends app",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Tinder: Dating app. Meet. Chat",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Hinge Dating App: Meet People",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Badoo - Dating. Chat. Meet.",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Dating and Chat - SweetMeet",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Hily: Dating app. Meet People. ",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Chispa: Dating App for Latinos",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "Dating with singles - iHappy",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "BLK Dating: Meet Black Singles",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Dating and Chat - Evermatch",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "Match Dating: Chat, Date, Meet",
        href: "/",
    },
    {
        id: 12,
        image: "/assets/Header/About/Our-company.svg",
        title: "OkCupid - Dating App",
        href: "/",
    },
    {
        id: 13,
        image: "/assets/Header/About/Our-company.svg",
        title: "Zoosk - Social Dating App",
        href: "/",
    },
];

export const dating2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Dating and chat - Maybe You",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "YouFlirt - flirt & chat app",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "3Fun: Threesome Couples Dating",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Seeking",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Turn Up - Match through music!",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Magnet: Meet & Chat Dating App",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Ukaku - Live video chat",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "InterracialCupid: Mixed Dating",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "AsianDating: Asian Dating",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Mingle2: Dating, Chat & Meet",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "Global Video call Random Cally",
        href: "/",
    },
];

export const dating3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Threesome Swingers App - 3way",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Wild: Hook up, Meet, Dating Me",
        href: "/",
    },
];

export const travelTransport1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "TripActions",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "TravelPerk",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "iGMS",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Hosthub",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Bookster",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Hostaway",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Tourwriter",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "NexTravel",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "Tourdesk",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Roundme",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "Bookeo",
        href: "/",
    },
    {
        id: 12,
        image: "/assets/Header/About/Our-company.svg",
        title: "NextBillion.ai",
        href: "/",
    },
];

export const travelTransport2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "YouLi",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Minim",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Itilite",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Routespring",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "FunAway",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "RateBoard",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Rentals United",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "BookingSync",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "Hostfully",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Tokeet",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "Locomote",
        href: "/",
    },
    {
        id: 12,
        image: "/assets/Header/About/Our-company.svg",
        title: "Ezus",
        href: "/",
    },
];

export const travelTransport3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "bookingkit",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Quicktext",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "MiX Telematics",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Phptravels",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Transporters.io",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Advensure",
        href: "/",
    },
];

export const eventsTickets1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Cvent",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Webex Events",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Hubilo",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Whova",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "vFairs",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Eventbrite",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Airmeet",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "Hopin",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "Bizzabo",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Tourdesk",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "BigMarker",
        href: "/",
    },
    {
        id: 12,
        image: "/assets/Header/About/Our-company.svg",
        title: "Swoogo",
        href: "/",
    },
    {
        id: 13,
        image: "/assets/Header/About/Eventene.svg",
        title: "Eventene",
        href: "/",
    },
];

export const eventsTickets2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "GoTo Webinar",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "PheedLoop",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Swapcard",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "EventX",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Zuddl",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "EventMobi",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "6Connex",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "Goldcast",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "Bevy",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "Blackthorn Events",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "Odoo Events",
        href: "/",
    },
    {
        id: 12,
        image: "/assets/Header/About/Our-company.svg",
        title: "Accelevents",
        href: "/",
    },
    {
        id: 13,
        image: "/assets/Header/About/Our-company.svg",
        title: "AnyRoad",
        href: "/",
    },
];

export const eventsTickets3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Canapii",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "FLOOR by 10Times",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Glue Up",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "EventCreate",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "ON24",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "Localist",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Sched",
        href: "/",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        title: "AudienceView Professional",
        href: "/",
    },
    {
        id: 9,
        image: "/assets/Header/About/Our-company.svg",
        title: "Explara Event Management Cloud",
        href: "/",
    },
    {
        id: 10,
        image: "/assets/Header/About/Our-company.svg",
        title: "InEvent",
        href: "/",
    },
    {
        id: 11,
        image: "/assets/Header/About/Our-company.svg",
        title: "Joyn",
        href: "/",
    },
];

export const socialNetworking1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Tribe",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Zoho Connect",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Flock",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Yammer",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "eXo Platform",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "SocialEngine",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "Whaller",
        href: "/",
    },
];

export const socialNetworking2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "HumHub",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "Disciple",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Open Source Social Network",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "SocialBuddy",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "Bitrix24",
        href: "/",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        title: "ConferenceNet",
        href: "/",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        title: "SkaDate",
        href: "/",
    },
];

export const socialNetworking3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        title: "Elgg",
        href: "/",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        title: "BuddyPress",
        href: "/",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        title: "Oxwall",
        href: "/",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        title: "Jcow",
        href: "/",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        title: "friendica",
        href: "/",
    },
];

export const onDemand1 = [
    {
        id: 1,
        image: "/assets/Header/industries/ondemand/Consumer goods.svg",
        href: "/on-demand/consumer-goods-and-services",
        title: "Consumer Goods",
    },
    {
        id: 2,
        image: "/assets/Header/industries/ondemand/Crowdfunding.svg",
        href: "/on-demand/crowdfunding-consulting-services",
        title: "Crowdfunding",
    },
    {
        id: 3,
        image: "/assets/Header/industries/ondemand/Education.svg",
        title: "Education",
        href: "/on-demand/education-app-development",
    },
    {
        id: 4,
        image: "/assets/Header/industries/ondemand/Recruiting.svg",
        title: "Recruiting",
        href: "/on-demand/recruiting-app-development",
    },
    {
        id: 5,
        image: "/assets/Header/industries/ondemand/Delivery.svg",
        title: "Delivery",
        href: "/on-demand/delivery-app-development",
    },
    {
        id: 6,
        image: "/assets/Header/industries/ondemand/Food.svg",
        title: "Food",
        href: "/on-demand/food-delivery-app-development",
    },
    {
        id: 7,
        image: "/assets/Header/industries/ondemand/Transportation.svg",
        title: "Transportation",
        href: "/on-demand/logistics-and-transportation-services",
    },
];

export const healthcareFitness1 = [
    {
        id: 1,
        image: "/assets/Header/industries/healthcare/electonic.svg",
        title: "Electronic Health Records Software",
        href: "/healthcare/electronic-health-records-software",
    },
    {
        id: 2,
        image: "/assets/Header/industries/healthcare/telemedicine.svg",
        title: "Telemedicine Software",
        href: "/healthcare/telemedicine-software-development-services",
    },
    {
        id: 3,
        image: "/assets/Header/industries/healthcare/medicalimage.svg",
        title: "Medical Image Analysis Software",
        href: "/healthcare/medical-image-analysis-software",
    },
    {
        id: 4,
        image: "/assets/Header/industries/healthcare/hospital.svg",
        title: "Hospital Management Software",
        href: "/healthcare/hospital-management-software-system",
    },
    {
        id: 5,
        image: "/assets/Header/industries/healthcare/e.svg",
        title: "E-Prescribing Software",
        href: "/healthcare/e-prescription-software-development",
    },
    {
        id: 6,
        image: "/assets/Header/industries/healthcare/remote.svg",
        title: "Remote Patient Monitoring Software",
        href: "/healthcare/remote-patient-monitoring",
    },

    {
        id: 7,
        image: "/assets/Header/industries/healthcare/health.svg",
        title: "Healthcare Billing Software",
        href: "/healthcare/healthcare-billing-software-development",
    },
    {
        id: 8,
        image: "/assets/Header/industries/healthcare/research.svg",
        title: "Medical Research Software",
        href: "/healthcare/medical-research",
    },
    {
        id: 9,
        image: "/assets/Header/industries/healthcare/mapp.svg",
        title: "Healthcare Mobile App Development",
        href: "/healthcare/healthcare-mobile-app-development",
    },
    {
        id: 10,
        image: "/assets/Header/industries/healthcare/fitnessn.svg",
        title: "Fitness Tracking App Development",
        href: "/healthcare/fitness-tracking-app-development",
    },
];

export const healthcareFitness2 = [
    {
        id: 1,
        image: "/assets/Header/industries/healthcare/trial.svg",
        title: "Clinical Trial Management Software",
        href: "/healthcare/clinical-trial-management-software",
    },
    {
        id: 2,
        image: "/assets/Header/industries/healthcare/PHR.svg",
        title: "PHR App Development",
        href: "/healthcare/phr-app-development",
    },
    {
        id: 3,
        image: "/assets/Header/industries/healthcare/Vector.svg",
        title: "Advanced Clinical Trial Management System",
        href: "/healthcare/ctms-solutions",
    },
    {
        id: 4,
        image: "/assets/Header/industries/healthcare/crm.svg",
        title: "Healthcare CRM Software",
        href: "/healthcare/dynamics-365-health-crm-solution",
    },
];
